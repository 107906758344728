

const Contact = () => {
  return ( 

    <div className="pt-5 mt-5 py-3 px-4" id="success">
        <div className="container">
            <div className="row">
                
                <div className="col-lg-6 col-12" id="domains">
                    <h1 className="mt-3 pt-lg-5 mt-lg-3 display-3 mb-4"><span style={{color: '#7ec3f7'}}>Contact</span> us</h1>
                    <p className="mb-3">Telephone: <b>+234 806 426 4641</b></p>
                    <p className="mb-3">Email: <b>dothostng@gmail.com</b></p>
                    <p className="mb-3">Address: <b>11, Oguntoye Street, Egbeda. Lagos State. Nigeria.</b></p>
                                
                </div>
                

            </div>
            
        </div>
    </div>


 );
};

export default Contact;